import React from "react"
import inventurHeader from "../../../assets/images/products/netstorsys/inventur_header.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import Outlook from "../Outlook"

const Inventur = () => {

  return (
    <>
      <section className="services-details-area pt-100" style={{ marginBottom: "3em" }}>
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={inventurHeader} alt="about" />
              </div>

              <div className="">
                <span className="products-sub-title">Schnelle Lagerkontrolle</span>
                <h1 className={"pt-3"}>Inventur mit NETSTORSYS</h1>
                <p>
                  Die manuelle Lagerinventur anhand gedruckter Barcode-Listen ist umständlich und fehleranfällig. Mit
                  dem Lagerverwaltungssystem NETSTORSYS lassen sich <b>Inventuren dagegen flexibel, schnell und
                  fehlerfrei
                  durchführen, per Batchsystem aufteilen und Mitarbeitern zuweisen.</b>
                </p>
                <p>
                  Der Status einer Inventur ist nach dem Scan von Lagerplatz, Artikel und einem Bestätigungsscan sofort
                  im System hinterlegt und muss nicht nachgetragen werden.
                </p>
                <h2>Perfekte Inventurstrategie, kurze Wege</h2>
                <p>
                  NETSTORSYS sendet Inventuraufträge direkt auf mobile Erfassungsgeräte. Dabei lassen sich verschiedene
                  Inventurstrategien festlegen. Mitarbeiter werden an die jeweiligen Inventurlagerplätze geführt, um
                  dort mit dem Gerät den aktuellen Bestand festzuhalten. Das WMS zieht Lagerplätze laufwegsoptimiert zur
                  Inventur heran.
                </p>
                <h2>Bestandskontrolle in Echtzeit</h2>
                <p>
                  Details der Inventur lassen sich positions- und artikelgenau einsehen. Das Lagerverwaltungssystem
                  vergleicht in Echtzeit physischen und verzeichneten Bestand und zeigt die aktuelle Abschlussrate sowie
                  die Fehlerrate an. Das LVS bietet die mitgeführte Lagerbereinigung – es ermöglicht, vor Ort sofort die
                  Lagerposition zu bereinigen, also den gebuchten an den tatsächlichen Bestand anzupassen. und Reports
                  für staatliche Organe erstellen.
                </p>
                <h2>Permanent prüfen, eindeutig zuordnen</h2>
                <p>
                  NETSTORSYS sperrt ein Lager für die Dauer der Stichtagsinventur, um aktuelle Daten zu gewährleisten,
                  und tauscht Daten mit beliebigen Vorsystemen, z.B. für ERP, aus. Optional implementierbar sind die
                  permanente Inventur, bei der nur Lagerpositionen in Inventur gesperrt werden, während das übrige Lager
                  weiterläuft, sowie zusätzliche, mit Barcodes verknüpfte Produktfotos, um die Zuordnung zu erleichtern.
                </p>
                <Outlook text={"Rechtssichere und fehlerfreie Inventur ohne unnötige Lagerpausen mit NETSTORSYS."} />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Inventur
